import React, { useContext, useState, Fragment } from 'react';
import Modal from 'react-modal';
import '../modal.css';
import { RecipesContext } from '../providers/RecipesContext';
import { UserContext } from '../providers/UserProvider';
import { useHistory } from 'react-router-dom';

const labelStyle = 'block mb-2 mx-4 text-gray-700 font-medium text-md';
const errorLabelStyle = 'block mb-2 mx-4 text-red-700 font-sm text-md';
const inputStyle =
  'appearance-none border-none w-1/2 md:w-4/5 rounded py-2 px-3 leading-tight focus:outline-none mx-4 mb-8';

Modal.setAppElement('#root');
const CreateRecipeModal = ({ modalIsOpen, closeModal }) => {
  const { addRecipe } = useContext(RecipesContext);
  const { currentUser } = useContext(UserContext);
  let history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState();

  const onAddRecipe = async (e) => {
    e.preventDefault();
    const recipe = { ...inputState };
    if (!recipe.name || recipe.name === '') {
      setError('Please provide a name for your recipe');
      return;
    }

    setIsLoading(true);
    const { id } = await addRecipe(recipe);
    setInputState({});
    setIsLoading(false);
    closeModal(modalIsOpen.modal);

    history.push(`/boards/${currentUser.username}/recipes/${id}`);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setError(null);
    setInputState({ ...inputState, [e.target.name]: e.target.value });
  };

  return (
    <Modal
      isOpen={modalIsOpen.open}
      onRequestClose={closeModal}
      shouldCloseOnEsc={true}
      className='modal-create-recipe'
      overlayClassName='Overlay'
      contentLabel='Example Modal'>
      {modalIsOpen.modal === 'copy' ? (
        <Fragment>
          <div className='modal-header py-4 pb-2 px-4  border-b text-xl font-semibold text-gray-800 border-gray-200'>
            Save recipe to my board
          </div>
          <div className='form-create-recipe p-4 my-16 h-full text-center'>
            <span className='font-medium'>
              You need an active subscription to copy this recipe to your board.
            </span>
            <br />
            However, this functionality is not yet available. Coming soon!
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className='modal-header py-4 pb-2 px-4  border-b text-xl font-semibold text-gray-800 border-gray-200'>
            Add new recipe
          </div>

          <form
            onSubmit={(e) => onAddRecipe(e)}
            className='form-create-recipe p-4 mt-4 h-full'>
            <label className={labelStyle}>Recipe name</label>
            {error && <label className={errorLabelStyle}>{error}</label>}
            <input
              type='text'
              className={inputStyle}
              placeholder='e.g: Pasta Carbonara'
              name='name'
              value={inputState.name}
              onChange={handleInputChange}
            />

            <label className={labelStyle}>Ingredients</label>
            <input
              type='text'
              className={inputStyle}
              placeholder='e.g: 2 eggs, 300g pasta, oil'
              name='ingredients'
              value={inputState.ingredients}
              onChange={handleInputChange}
            />

            <label className={labelStyle}>URL</label>
            <input
              type='url'
              className={inputStyle}
              placeholder='e.g: https://nyt.com/cooking'
              name='URL'
              value={inputState.URL}
              onChange={handleInputChange}
            />

            <div className='modal-footer flex text-center'>
              <button
                type='submit'
                className={` ${
                  loading && 'opacity-50 cursor-not-allowed'
                } flex-grow flex justify-center mb-1 items-center px-4 py-2 border border-transparent  leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 transition duration-150 ease-in-out`}>
                <svg
                  className='-ml-1 mr-2 h-5 w-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'>
                  <path
                    fillRule='evenodd'
                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
                Add recipe
              </button>
              {/* <button className='text-gray-600 font-light underline'>cancel</button> */}
            </div>
          </form>
        </Fragment>
      )}
    </Modal>
  );
};

export default CreateRecipeModal;
