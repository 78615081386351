import React, { useState, useEffect, useContext } from 'react';
import { db } from '../firebase';
import { UserContext } from './UserProvider';

// [provider, consumer]
export const RecipesContext = React.createContext();

// - [x] move state management to here
// - [x] hug app in this context
// - [x] useContext in components that need it
// - [x] refactor array into objects
// - [ ] use reducer for better state management

export const RecipesProvider = ({ children }) => {
  const [recipes, setRecipes] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [viewingUser, setViewingUser] = useState();
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (!viewingUser) {
      return;
    }

    return db
      .collection('recipes')
      .where('userObj.username', '==', viewingUser)
      .onSnapshot((snap) => {
        let recipes = {};

        if (snap.empty) {
          if (currentUser && currentUser.username === viewingUser)
            recipes = { error: "Looks like you don't have any recipes yet!" };
          else
            recipes = {
              error:
                'Snap! Looks like this user has no recipes or does not exist.',
            };
        } else {
          snap.forEach((doc) => {
            recipes[doc.id] = { id: doc.id, ...doc.data() };
          });
        }
        setRecipes(recipes);
        setIsLoading(false);
      });
  }, [currentUser, viewingUser]);

  const addRecipe = ({ name, ingredients, URL }) => {
    const _ingredients = ingredients ? ingredients.split(',') : [];
    if (URL && !URL.includes('http://') && !URL.includes('https://'))
      URL = `http://${URL}`;

    const r = {
      name,
      ingredients: _ingredients,
      notes: 'No notes yet.',
      images: [],
      tags: [],
      userObj: currentUser,
      version: 2,
    };
    if (URL) r.URL = URL;
    return db.collection('recipes').add(r);
  };

  const removeRecipe = (id) => {
    return db.collection('recipes').doc(id).delete();
  };

  const updateRecipe = (id, fields) => {
    return db.collection('recipes').doc(id).update(fields);
  };

  const getRecipe = (id) => {
    return db.doc(`recipes/${id}`).get();
  };

  const filterRecipes = async (query) => {
    const recipes = {};
    // hacky search
    // https://stackoverflow.com/a/56815787/1889935
    const snap = await db
      .collection('recipes')
      .where('name', '>=', query)
      .where('name', '<=', query + '\uf8ff')
      .where('userObj.username', '==', viewingUser)
      .get();
    snap.forEach((doc) => {
      recipes[doc.id] = doc.data();
    });
    setRecipes(recipes);
  };

  const value = {
    recipes,
    setRecipes,
    addRecipe,
    removeRecipe,
    updateRecipe,
    getRecipe,
    filterRecipes,
    isLoading,
    viewingUser,
    setViewingUser,
  };
  return (
    <RecipesContext.Provider value={value}>{children}</RecipesContext.Provider>
  );
};
