import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../providers/UserProvider';
import { Link } from 'react-router-dom';

const Subscribers = () => {
  const { getCurrentUserSubscribers } = useContext(UserContext);

  const [subscribers, setSubscribers] = useState([]);

  useEffect(() => {
    const fetchSubscribers = async () => {
      const refs = await getCurrentUserSubscribers();

      Promise.all(refs).then((subscriberSnaps) => {
        const subscribers = [];
        subscriberSnaps.forEach((sub) => {
          subscribers.push({ id: sub.id, ...sub.data() });
        });
        setSubscribers(subscribers);
      });
    };

    fetchSubscribers();
  }, [getCurrentUserSubscribers]);

  return (
    <div className='container mx-auto my-12  p-8'>
      <h1 className='text-xl text-gray-900 my-2 font-bold'>Your subscribers</h1>
      {/* <input
        type='text'
        placeholder='search'
        className='border rounded px-2 py-1'
      /> */}
      <table className='m-auto border p-4 table w-full mt-2 text-center'>
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {subscribers &&
            subscribers.map((subscriber) => (
              <tr key={subscriber.id}>
                <td className='p-2'>{subscriber.username}</td>
                <td>{subscriber.email}</td>
                <td>
                  <Link
                    className='cursor-pointer px-2 py-1 bg-gray-400 hover:bg-gray-300 rounded font-semibold text-sm'
                    to={`/boards/${subscriber.username}`}>
                    view board
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Subscribers;
