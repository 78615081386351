import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';

export const UserContext = React.createContext(null);

const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const [displayFakeAuth, setDisplayFakeAuth] = useState(
    localStorage.getItem('fake-auth') === 'true'
  );
  useEffect(() => {
    if (displayFakeAuth) {
      setIsLoadingUser(false);
      return setCurrentUser({
        id: 'lU98XVpaLiaZtyGLGpCjdIJU5a62',
        username: 'toon.verbeek1',
      });
    }
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const username = user.email.substring(0, user.email.indexOf('@'));
        const { photoURL } = user;
        const u = await db.doc(`users/${user.uid}`).get();
        setCurrentUser({ id: user.uid, photoURL, username, ...u.data() });
      } else {
        setCurrentUser(null);
      }
      setIsLoadingUser(false);
    });
    return unsubscribe;
  }, [displayFakeAuth]);

  const findUserByEmail = async (email) => {
    const snap = await db.collection('users').where('email', '==', email).get();
    if (snap.docs.length === 0)
      return { error: "We couldn't find a user with that email" };

    return { id: snap.docs[0].id, ref: snap.docs[0], ...snap.docs[0].data() };
  };

  const getCurrentUserSubscribers = async () => {
    const snap = await db
      .doc(`stripeUsers/${currentUser.stripePlan}`)
      .collection('subscribers')
      .get();

    let refs = [];
    snap.docs.forEach((doc) => {
      const recipeboardUserRef = doc.data().userObj;
      if (recipeboardUserRef) {
        const ref = recipeboardUserRef.get();
        refs.push(ref);
      }
    });

    return refs;
  };

  const getSubscriptions = async () => {
    const snap = await db
      .collectionGroup('subscribers')
      .where('userObj', '==', db.doc(`users/${currentUser.id}`))
      .get();

    if (snap.empty) return [];
    const subscribedToId = snap.docs[0].ref.parent.parent.id;

    const subscribedToSnap = await db
      .collection('users')
      .where('stripePlan', '==', subscribedToId)
      .get();

    if (subscribedToSnap.empty) return [];

    return subscribedToSnap.docs;
  };

  // connects the current user to a stripe subscription
  const restoreSubscription = async (email, code) => {
    const subscriptionSnap = await db
      .collectionGroup('subscribers')
      .where('inviteCode', '==', code)
      .where('email', '==', email)
      .get();

    if (subscriptionSnap.size === 0)
      return alert(
        "We didn't find any records matching your email and code. Please check and try again."
      );

    await subscriptionSnap.docs[0].ref.update({
      userObj: db.doc(`users/${currentUser.id}`),
    });

    alert('Your subscription has been restored!');
    return subscriptionSnap.docs[0].data();
  };

  const value = {
    currentUser,
    displayFakeAuth,
    setDisplayFakeAuth,
    setCurrentUser,
    isLoadingUser,
    findUserByEmail,
    getCurrentUserSubscribers,
    restoreSubscription,
    getSubscriptions,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
