import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';
import { signOut } from '../firebase';
import { RecipesContext } from '../providers/RecipesContext';

const Header = ({ setModalIsOpen, hideMenu }) => {
  const { currentUser, setDisplayFakeAuth, displayFakeAuth } = useContext(
    UserContext
  );

  const [activeTab, setActiveTab] = useState('recipes');
  const [hoveringMenu, setHoveringMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const { viewingUser } = useContext(RecipesContext);
  const showDevTools = process.env.NODE_ENV === 'development';

  console.log(hoveringMenu);
  // if we're logged in and we're viewing a board that is not ours
  const viewingOtherBoard =
    currentUser && viewingUser && currentUser.username !== viewingUser;

  const isCreator = currentUser && currentUser.stripePlan;

  // useEffect(() => {
  //   document.addEventListener('mousedown', () => {
  //     if (!hoveringMenu) {
  //       console.log(hoveringMenu);
  //       setShowSubMenu(false);
  //     } else console.log('were hovering');
  //   });
  //   const unsubscribe = () => {
  //     document.removeEventListener(
  //       'mousedown',
  //       () => !hoveringMenu && setShowSubMenu(false)
  //     );
  //   };
  //   return unsubscribe();
  // }, [hoveringMenu]);

  const tabStyle = (tabName) =>
    `${
      activeTab === tabName
        ? 'border hover:bg-gray-100 bg-gray-300 font-medium text-gray-900'
        : 'hover:bg-gray-100 hover:text-gray-900 hover:font-medium'
    } px-6 rounded-lg mx-2 py-1`;
  return (
    <div
      className={`${
        hideMenu ? 'hidden lg:flex' : 'flex'
      }  w-full bg-gray-900 items-center p-4 text-white align-middle`}>
      <span className='mr-8 flex-grow'>
        {/* if viewing my board or my settings or we're not logged in, show recipeboard logo */}
        {!viewingOtherBoard && (
          <Link className='text-xl ' to='/'>
            <button onClick={() => setActiveTab('recipes')}>
              recipe<strong>board</strong>
            </button>
          </Link>
        )}

        {/* only if viewing someone else's board, show back to my board */}
        {viewingOtherBoard && (
          <Link
            to='/'
            className='bg-gray-300 px-2 py-1 rounded-md hover:bg-gray-100 flex-grow cursor-pointer text-gray-900 font-semibold'>
            ← Back to my board
          </Link>
        )}
      </span>
      {currentUser && !viewingOtherBoard && (
        <div className='hidden lg:inline-flex lg:flex-grow'>
          <Link to='/'>
            <button
              onClick={() => setActiveTab('recipes')}
              className={tabStyle('recipes')}>
              recipes
            </button>
          </Link>
          {isCreator && (
            <Link to='/subscribers'>
              <button
                onClick={() => setActiveTab('subscribers')}
                className={tabStyle('subscribers')}>
                subscribers
              </button>
            </Link>
          )}
          {showDevTools && (
            <Link to='/subscriptions'>
              <button
                onClick={() => setActiveTab('subscriptions')}
                className={tabStyle('subscriptions')}>
                subscriptions
              </button>
            </Link>
          )}
        </div>
      )}
      {currentUser && !viewingOtherBoard && (
        <button
          onClick={() =>
            setModalIsOpen({
              modal: 'create',
              open: true,
            })
          }
          className='hidden lg:inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 transition duration-150 ease-in-out'>
          <svg className='-ml-1 -mt-1 mr-2 h-5 w-5' fill='currentColor'>
            <path d='M17 11C17.2652 11 17.5196 11.1054 17.7071 11.2929C17.8946 11.4804 18 11.7348 18 12C18 12.2652 17.8946 12.5196 17.7071 12.7071C17.5196 12.8946 17.2652 13 17 13H13V17C13 17.2652 12.8946 17.5196 12.7071 17.7071C12.5196 17.8946 12.2652 18 12 18C11.7348 18 11.4804 17.8946 11.2929 17.7071C11.1054 17.5196 11 17.2652 11 17V13H7C6.73478 13 6.48043 12.8946 6.29289 12.7071C6.10536 12.5196 6 12.2652 6 12C6 11.7348 6.10536 11.4804 6.29289 11.2929C6.48043 11.1054 6.73478 11 7 11H11V7C11 6.73478 11.1054 6.48043 11.2929 6.29289C11.4804 6.10536 11.7348 6 12 6C12.2652 6 12.5196 6.10536 12.7071 6.29289C12.8946 6.48043 13 6.73478 13 7V11H17Z' />
          </svg>
          New Recipe
        </button>
      )}

      {currentUser && (
        <div className='ml-4 mr-4 cursor-pointer inline-flex'>
          {showDevTools && (
            <span
              onClick={() => {
                localStorage.setItem('fake-auth', !displayFakeAuth);
                setDisplayFakeAuth(
                  localStorage.getItem('fake-auth') === 'true'
                );
              }}
              className={`${
                displayFakeAuth ? 'bg-red-600' : 'bg-gray-600'
              }  hidden lg:inline-block rounded-lg px-2 py-1 mr-4 text-gray-100 font-semibold`}>
              Fake Auth {displayFakeAuth ? 'ON' : 'OFF'}
            </span>
          )}
          {!displayFakeAuth && (
            <div
              className='relative'
              onMouseEnter={() => setHoveringMenu(true)}
              onMouseLeave={() => setHoveringMenu(false)}>
              <div
                onClick={() => setShowSubMenu(!showSubMenu)}
                className='profile-image overflow-hidden rounded-full w-8 h-8
              bg-red-300'>
                <img src={currentUser.photoURL} alt='profile' />
              </div>
              {showSubMenu && (
                <div className='absolute z-20 sub-menu right-1/2 w-48 bg-white shadow-sm rounded'>
                  <span className='w-full block menu-item font-semibold text-gray-900 m-auto px-4 py-4 cursor-default border-b border-gray-200'>
                    {currentUser.name}
                  </span>
                  <Link
                    to='/'
                    className='lg:hidden w-full block menu-item text-gray-900 m-auto px-4 py-2 border-b border-gray-200 hover:bg-gray-200'>
                    Recipes
                  </Link>
                  <Link
                    to='/subscribers'
                    className='lg:hidden w-full block menu-item text-gray-900 m-auto px-4 py-2 border-b border-gray-200 hover:bg-gray-200'>
                    Subscribers
                  </Link>
                  <span
                    className='w-full block menu-item text-gray-900 m-auto px-4 py-2 border-b border-gray-200 hover:bg-gray-200'
                    onClick={signOut}>
                    Sign out
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
