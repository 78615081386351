import React from 'react';
import { auth, uiConfig } from '../firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const Signin = () => {
  return (
    <div className=''>
      <StyledFirebaseAuth
        className='mt-16'
        uiConfig={uiConfig}
        firebaseAuth={auth}
      />
    </div>
  );
};

export default Signin;
