import React from 'react';
import RecipeImage from './RecipeImage';

const RecipeImages = ({ images, removeImage, isOwner }) => {
  return (
    <div
      style={{ height: '350px', maxWidth: '1100px' }}
      className='recipe-images-container rounded-lg overflow-hidden flex mb-8 '>
      <RecipeImage
        isOwner={isOwner}
        index={0}
        image={images[0]}
        containerStyle={{
          minWidth: '50%',
          height: '100%',
          marginRight: images.length > 1 && '0.5rem',
        }}
        removeImage={removeImage}
        totalImages={images.length}
      />

      {/* Second column of images */}
      {images.length > 1 && (
        <div
          style={{ minWidth: '25%', height: '100%' }}
          className='recipe-secondary-images-container inline-flex flex-col overflow-hidden mr-2'>
          {images.map((image, index) => {
            if (index > 0 && index < 3) {
              return (
                <RecipeImage
                  isOwner={isOwner}
                  key={image}
                  index={index}
                  image={image}
                  removeImage={removeImage}
                  totalImages={images.length}
                />
              );
            }
            return null;
          })}
        </div>
      )}
      {/* 3rd column of images */}
      {images.length > 3 && (
        <div
          style={{ minWidth: '25%', height: '100%' }}
          className='recipe-secondary-images-container inline-flex flex-col overflow-hidden'>
          {images.map((image, index) => {
            if (index >= 3 && index < 6) {
              return (
                <RecipeImage
                  isOwner={isOwner}
                  key={image}
                  index={index}
                  image={image}
                  removeImage={removeImage}
                  totalImages={images.length}
                />
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default RecipeImages;
