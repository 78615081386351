import React, { useContext, useEffect, useState, Fragment } from 'react';
import { RecipesContext } from '../providers/RecipesContext';
import { useParams, Link, useHistory } from 'react-router-dom';
import Ingredient from './Ingredient';
import { UserContext } from '../providers/UserProvider';
import { storage } from '../firebase';
import ImageUploader from 'react-images-upload';
import RecipeImages from './RecipeImages';

const Recipe = ({ setModalIsOpen }) => {
  const [currentRecipe, setCurrentRecipe] = useState({});
  const [hovering, setHovering] = useState({});
  const [editing, setEditing] = useState({});
  const [editNotes, setEditNotes] = useState(false);
  const [editHeader, setEditHeader] = useState(false);
  const [inputState, setInputState] = useState({});
  const [uploadStatus, setUploadStatus] = useState(null);
  const [progress, setProgress] = useState(0);

  // TODO: refactor image uploading into separate component
  const onDrop = (pictures) => {
    handleUpload(pictures[0]);
  };

  const handleUpload = (image) => {
    const uploadTask = storage
      .ref(`images/${currentUser.id}/${image.name}`)
      .put(image);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        setUploadStatus({ type: 'in_progress', text: 'uploading' });
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref(`images/${currentUser.id}`)
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            const r = { ...currentRecipe };
            r.images ? r.images.push(url) : (r.images = [url]);
            updateRecipe(id, r);
            setUploadStatus({
              type: 'success',
              text: 'Successfully uploaded image!',
            });
            setProgress(null);
            // setTimeout(setUploadStatus(null), 5000);
          });
      }
    );
  };

  const { removeRecipe, updateRecipe, getRecipe } = useContext(RecipesContext);
  const { currentUser } = useContext(UserContext);
  const { id } = useParams();

  let history = useHistory();

  useEffect(() => {
    async function fetchRecipe() {
      const r = await getRecipe(id);
      setCurrentRecipe({ id: r.id, ...r.data() });
    }
    fetchRecipe();
  }, [id, getRecipe, setCurrentRecipe]);

  const onRemoveRecipe = async (id) => {
    if (window.confirm(`Are you sure you want to delete ${id}`)) {
      removeRecipe(id);
      history.push('/');
    }
  };

  const onUpdateRecipe = (id, fields) => {
    if (window.confirm(`Are you sure you want to update ${id}`)) {
      updateRecipe(id, fields);
    }
  };

  const onRemoveIngredient = (index) => {
    const r = { ...currentRecipe };
    r.ingredients.splice(index, 1);
    updateRecipe(id, r);
  };

  const updateIngredient = (index, ingredient) => {
    const r = { ...currentRecipe };
    r.ingredients[index] = ingredient;
    updateRecipe(id, r);
    setEditing({ [index]: false });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setInputState({ ...inputState, [e.target.name]: e.target.value });
  };

  const removeImage = (index) => {
    const r = { ...currentRecipe };
    r.images.splice(index, 1);
    updateRecipe(id, r);
  };

  if (!currentRecipe || !currentRecipe.name) return <div>Loading</div>;
  const { name, ingredients, notes, URL } = currentRecipe;
  const isOwner = currentUser
    ? currentRecipe.userObj.username === currentUser.username
    : false;

  return (
    <div className='recipe-container lg:p-8 lg:shadow-md lg:rounded-md bg-white'>
      <div className='recipe-header mb-8 lg:flex lg:items-baseline'>
        <div className='w-full top-0 sticky lg:w-auto lg:relative  lg:flex-grow items-baseline bg-white inline-flex lg:bg-transparent'>
          {!editHeader && (
            <Link
              to='/'
              className='lg:hidden rounded bg-gray-400 text-gray-800 inline-block text-2xl w-20 text-center '>
              ←
            </Link>
          )}
          <div className='p-3 text-2xl lg:p-0 flex-grow text-center lg:text-left truncate '>
            {editHeader ? (
              <Fragment>
                <input
                  autoFocus
                  className='border border-gray-300 p-2 rounded w-full lg:w-4/5 text-base outline-none mb-1 block'
                  placeholder='title'
                  type='Recipe title'
                  name='title'
                  value={inputState['title']}
                  onChange={handleChange}
                />

                <input
                  className='border border-gray-300 p-2 rounded w-full lg:w-4/5 text-base outline-none block'
                  type='url'
                  name='URL'
                  placeholder='URL'
                  value={inputState['URL']}
                  onChange={handleChange}
                />

                <div className='block upload-wrapper border rounded mt-2 mr-4 w-full lg:w-4/5'>
                  <ImageUploader
                    singleImage
                    buttonText={progress ? 'Loading...' : 'Add image'}
                    label='Upload an image (maximum 20MB)'
                    fileContainerStyle={{ boxShadow: 'unset' }}
                    buttonStyles={{ borderRadius: '8px' }}
                    withIcon={true}
                    onChange={onDrop}
                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                    // maxFileSize={5242880}
                  />
                  {uploadStatus && (
                    <span
                      className={`${
                        uploadStatus.type === 'in_progress'
                          ? 'text-gray-700'
                          : 'text-green-700'
                      } font-medium text-sm text-center p-4 block`}>
                      {uploadStatus.text}
                    </span>
                  )}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <span className=''>{name}</span>
                {URL && (
                  <span className='block text-sm text-blue-700 cursor:pointer'>
                    <a href={URL} rel='noopener noreferrer' target='_blank'>
                      View original recipe
                    </a>
                  </span>
                )}
              </Fragment>
            )}
          </div>
        </div>

        <div className='text-sm w-full text-gray-600 flex items-center cursor-pointer lg:w-auto lg:align-middle justify-end'>
          {!editHeader && isOwner && (
            <span
              className='text-gray-900 w-full m-1 p-4 border border-gray-200 rounded-sm font-medium text-center lg:w-auto lg:text-gray-900 cursor-pointer lg:hover:bg-red-700 lg:hover:text-white lg:leading-5  lg:px-3 lg:py-1 lg:mb-3 lg:rounded'
              onClick={() => onRemoveRecipe(id)}>
              Remove recipe
            </span>
          )}
          {editHeader ? (
            <div className='edit-recipe-header-menu self-end'>
              <span
                className='text-sm text-gray-900 hover:font-normal cursor-pointer'
                onClick={() => setEditHeader(false)}>
                cancel
              </span>
              <span
                className='ml-1  text-white cursor-pointer bg-green-900 hover:bg-green-700 hover:text-white leading-5  px-3 py-1 mb-3 rounded'
                onClick={() => {
                  onUpdateRecipe(id, {
                    name: inputState['title'],
                    URL: inputState['URL'] ? inputState['URL'] : null,
                  });
                  setEditHeader(false);
                }}>
                save
              </span>
            </div>
          ) : isOwner ? (
            <span
              onClick={() => {
                setEditHeader(true);
                setInputState({
                  title: currentRecipe.name,
                  URL: currentRecipe.URL,
                });
              }}
              className='bg-blue-700 w-full m-1 p-4 text-white rounded-sm font-medium text-center lg:w-auto lg:bg-transparent lg:text-gray-900 cursor-pointer lg:hover:bg-gray-900 lg:hover:text-white lg:leading-5 lg:border lg:border-gray-200 lg:px-3 lg:py-1 lg:mb-3 lg:rounded lg:ml-1'>
              Edit recipe
            </span>
          ) : (
            currentUser && (
              <span
                onClick={() =>
                  setModalIsOpen({
                    modal: 'copy',
                    open: true,
                  })
                }
                className='bg-purple-700 w-full m-1 p-4 text-white rounded-sm font-medium text-center lg:w-auto lg:bg-transparent lg:text-gray-900 cursor-pointer lg:hover:bg-purple-900 lg:hover:text-white lg:leading-5 lg:border lg:border-gray-200 lg:px-3 lg:py-1 lg:mb-3 lg:rounded lg:ml-1'>
                Copy to my board
              </span>
            )
          )}
        </div>
      </div>
      {!editHeader &&
        currentRecipe.images &&
        currentRecipe.images.length > 0 && (
          <RecipeImages
            removeImage={removeImage}
            isOwner={isOwner}
            images={currentRecipe.images}
          />
        )}
      <ol className='border border-gray-300 lg:border-0 text-gray-800'>
        {ingredients.map((ingredient, index) => (
          <li
            key={`${index}-${ingredient}`}
            className='my-2 flex justify-between border-gray-200 border-b p-3 hover:bg-gray-100 mx-2 rounded'
            onMouseEnter={() => setHovering({ [index]: true })}
            onMouseLeave={() => setHovering({ [index]: false })}>
            <Ingredient
              updateIngredient={(ingredient) =>
                updateIngredient(index, ingredient)
              }
              isOwner={isOwner}
              hovering={hovering[index]}
              ingredient={ingredient}
              onRemoveIngredient={() => onRemoveIngredient(index)}
            />
          </li>
        ))}
        {isOwner && (
          <li className='flex items-center p-2'>
            <input
              name='new-ingredient'
              value={inputState['new-ingredient']}
              onFocus={() => setEditing({ [ingredients.length]: true })}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (inputState['new-ingredient'] !== '') {
                    updateRecipe(id, {
                      ingredients: [
                        ...ingredients,
                        inputState['new-ingredient'],
                      ],
                    });
                    setEditing({ [ingredients.length]: false });
                    setInputState({ 'new-ingredient': '' });
                  }
                }
              }}
              className='border border-gray-200 p-1 outline-none  mr-1 block'
              onChange={handleChange}
              placeholder='new ingredient...'
            />
            {editing[ingredients.length] && (
              <span
                className='text-white cursor-pointer bg-green-900 hover:bg-green-700 hover:text-white border border-gray-200 px-3 py-1 rounded'
                onClick={() => {
                  updateRecipe(id, {
                    ingredients: [...ingredients, inputState['new-ingredient']],
                  });
                  setEditing({ [ingredients.length]: false });

                  setInputState({ 'new-ingredient': '' });
                }}>
                save
              </span>
            )}
          </li>
        )}
      </ol>
      {editNotes ? (
        <Fragment>
          <div className='edit-notes-menu flex justify-end items-baseline mt-6'>
            <span
              className='text-sm text-gray-900 hover:font-normal cursor-pointer'
              onClick={() => {
                setEditNotes(false);
              }}>
              cancel
            </span>
            <span
              className='ml-1  text-white cursor-pointer bg-green-900 hover:bg-green-700 hover:text-white leading-5  border border-gray-200 px-3 py-1 mb-3 rounded'
              onClick={() => {
                updateRecipe(id, { notes: inputState['notes'] });
                setEditNotes(false);
              }}>
              save
            </span>
          </div>
          <textarea
            name='notes'
            className='w-full h-64 border border-gray-200 p-3'
            autoFocus
            onChange={handleChange}
            value={inputState['notes']}
          />
        </Fragment>
      ) : (
        <div className='recipe-notes mt-6 flex flex-col'>
          {isOwner && (
            <span
              className='self-end  text-gray-900 cursor-pointer hover:bg-gray-900 hover:text-white leading-5  border border-gray-200 px-3 py-1 mb-3 rounded'
              onClick={() => {
                setEditNotes(true);
                setInputState({ notes: currentRecipe.notes });
              }}>
              edit
            </span>
          )}
          <p className='bg-gray-100 whitespace-pre-wrap p-8 w-full'>
            <span className='text-xl font-semibold leading-10'>
              Instructions
            </span>
            <br />
            {notes}
          </p>
        </div>
      )}
    </div>
  );
};

export default Recipe;
