import React, { useState } from 'react';
import { Route, useHistory, Switch, Redirect } from 'react-router-dom';
import { useContext } from 'react';

import './main.css';

import Recipes from './components/Recipes';
import Signin from './components/Signin';
import Header from './components/Header';
import Recipe from './components/Recipe';
import CreateRecipeModal from './components/CreateRecipeModal';
import TagCloud from './components/TagCloud';

import { PrivateRoute } from './components/PrivateRoute';
import { UserContext } from './providers/UserProvider';
import Subscribers from './components/Subscribers';
import Subscriptions from './components/Subscriptions';

/* 
- [x] Routing
- [x] Authentication
- [x] Mobile first
- [x] Add new recipe
- [x] Update recipe
- [x] Only allow owner of recipe to edit
- [x] View other user's recipes
  - [x] Change URL to /user/recipes/id
- [ ] Copy recipe to your own board
- [x] CRUD tags (collections?)
- [ ] Next / previous
- [ ] Cooking mode
- [ ] Set firebase rules to only allow modification of authenticated user
- [ ] Add recipe search
- [x] Fix create recipe modal on mobile

]
*/
function App() {
  let history = useHistory();
  const hideMenu = history.location.pathname.indexOf('recipes') > 1;
  const [modalIsOpen, setModalIsOpen] = useState({ modal: '', open: false });
  const { currentUser } = useContext(UserContext);

  return (
    <div className='App flex flex-col h-screen'>
      <Header hideMenu={hideMenu} setModalIsOpen={setModalIsOpen} />
      <Switch>
        <Route exact path='/login'>
          {currentUser ? (
            <Redirect to={`/boards/${currentUser.username}`} />
          ) : (
            <Signin />
          )}
        </Route>
        <PrivateRoute exact path='/'>
          {currentUser && <Redirect to={`/boards/${currentUser.username}`} />}
        </PrivateRoute>
        <PrivateRoute exact path='/subscribers'>
          <Subscribers />
        </PrivateRoute>
        <PrivateRoute exact path='/subscriptions'>
          <Subscriptions />
        </PrivateRoute>

        {/* TODO: rename path from /:username to /boards/:username */}
        <div className='main-container py-4 px-6 h-full lg:p-0 lg:flex lg:flex-grow lg:overflow-auto'>
          <Route path='/boards/:username'>
            <div
              className={`recipes-container ${
                hideMenu && 'hidden'
              } bg-white h-full w-screen absolute left-0 lg:relative lg:h-auto lg:overflow-auto lg:block lg:max-w-lg lg:flex-shrink-0 lg:w-1/4 `}>
              <Recipes />
            </div>
          </Route>

          <div className='recipe-container lg:w-full lg:bg-gray-100 lg:p-12 xl:p-20 lg:h-full lg:overflow-auto'>
            <Route path='/boards/:username/recipes/:id'>
              <div className='recipe-wrapper lg:max-w-4xl lg:m-auto'>
                <TagCloud />
                <Recipe setModalIsOpen={setModalIsOpen} />
              </div>
            </Route>
          </div>
        </div>
      </Switch>
      <CreateRecipeModal
        modalIsOpen={modalIsOpen}
        closeModal={(modal) => setModalIsOpen({ modal, open: false })}
      />
    </div>
  );
}

export default App;
