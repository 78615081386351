import React, { useContext, useEffect, useState } from 'react';
import { RecipesContext } from '../providers/RecipesContext';
import { Link, useParams } from 'react-router-dom';

const Recipes = () => {
  const { recipes, isLoading, setViewingUser } = useContext(RecipesContext);
  const [active, setActive] = useState({});

  const { username } = useParams();

  useEffect(() => {
    setViewingUser(username);
  }, [setViewingUser, username]);

  if (isLoading) return <div className='w-full text-center'>Loading</div>;
  if (recipes.error) {
    return (
      <div className='recipes-error fixed top-1/2 lg:left-1/2 bg-orange-300 p-8 font-semibold rounded-sm'>
        {recipes.error}
      </div>
    );
  }
  return (
    <div className='bg-white text-gray-600 '>
      {Object.keys(recipes).map((key) => {
        return (
          <Link key={key} to={`/boards/${username}/recipes/${key}`}>
            <div
              onClick={() => setActive(key)}
              className={`${
                active === key && 'bg-gray-300'
              } p-6 lg:m-2 rounded overflow-hidden border-b border-gray-200 lg:border-none hover:bg-gray-300 cursor-pointer`}>
              <div className='inline-flex items-center'>
                <div className='bg-gray-300 rounded w-8 h-8 relative overflow-hidden mr-3'>
                  {recipes[key].images && recipes[key].images.length > 0 && (
                    <img
                      alt='recipe thumbnail'
                      className='object-cover h-full w-full'
                      src={recipes[key].images[0]}
                    />
                  )}
                </div>
                {recipes[key].name}
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Recipes;
