import React, { useState } from 'react';

const RecipeImage = ({
  image,
  index,
  removeImage,
  isOwner,
  totalImages,
  containerStyle,
}) => {
  const [hoveringOverImage, setHoveringOverImage] = useState({});
  const removeButtonStyle =
    'cursor-pointer absolute right-0 top-0 py-2 px-4 rounded hover:bg-gray-400 bg-gray-500 text-gray-100';
  return (
    <div
      key={image}
      onMouseEnter={() => setHoveringOverImage({ [index]: true })}
      onMouseLeave={() => setHoveringOverImage({ [index]: false })}
      style={containerStyle || { minWidth: '25%', minHeight: '50%' }}
      className={`${
        ((index === 1 && totalImages > 2) ||
          (index === 3 && totalImages > 4)) &&
        'mb-2'
      } recipe-secondary-container flex-grow rounded relative overflow-hidden`}
    >
      <img src={image} className='object-cover h-full w-full' alt='recipe' />
      {isOwner && hoveringOverImage[index] && (
        <div onClick={() => removeImage(index)} className={removeButtonStyle}>
          remove
        </div>
      )}
    </div>
  );
};

export default RecipeImage;
