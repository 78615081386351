import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyAUXhS1Ia0lSHkqwJpcVXSBytbDKDAiyV8',
  authDomain: 'recipe-tagger.firebaseapp.com',
  databaseURL: 'https://recipe-tagger.firebaseio.com',
  projectId: 'recipe-tagger',
  storageBucket: 'recipe-tagger.appspot.com',
  messagingSenderId: '507493244557',
  appId: '1:507493244557:web:39fd7468309620a8434cc4',
};

firebase.initializeApp(firebaseConfig);
export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
};
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const signOut = () => {
  console.log('signing out');
  auth.signOut();
};
