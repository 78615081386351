import React, { useContext, useState, useEffect } from 'react';
import { RecipesContext } from '../providers/RecipesContext';
import { useParams } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';

const TagCloud = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputState, setInputState] = useState();
  const [currentRecipe, setCurrentRecipe] = useState();
  const { currentUser } = useContext(UserContext);

  const { updateRecipe, getRecipe } = useContext(RecipesContext);
  const { id } = useParams();

  useEffect(() => {
    async function fetchRecipe() {
      const r = await getRecipe(id);
      setCurrentRecipe({ id: r.id, ...r.data() });
    }
    fetchRecipe();
  }, [id, getRecipe, setCurrentRecipe]);

  if (!currentRecipe || !currentRecipe.tags) return null;

  const handleChange = (e) => {
    e.preventDefault();
    setInputState(e.target.value);
  };

  const resetState = () => {
    setIsEditing(false);
    setInputState('');
  };
  const { tags } = currentRecipe;
  const isOwner = currentUser ? currentRecipe.userObj.username === currentUser.username : false;
  return (
    <div className='tags hidden mb-3 lg:inline-flex'>
      {tags.map((tag, index) => (
        <span
          key={tag}
          className='bg-black text-white p-2 font-medium leading-5 text-xl mr-1 rounded'
        >
          #{tag}
          {isOwner && (
            <span
              className='text-sm ml-4 cursor-pointer'
              onClick={() => {
                tags.splice(index, 1);
                updateRecipe(id, { tags });
              }}
            >
              x
            </span>
          )}
        </span>
      ))}
      {isEditing ? (
        <input
          className='border border-gray-200 p-1 w-4/5 text-base outline-none mb-1 block'
          onChange={handleChange}
          autoFocus
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (inputState !== '') {
                updateRecipe(id, {
                  tags: [...tags, inputState],
                });
                resetState();
              }
            }
            if (e.key === 'Escape') {
              e.preventDefault();
              resetState();
            }
          }}
          onBlur={(e) => {
            e.preventDefault();
            resetState();
          }}
          name='tag-input'
          value={inputState}
          placeholder='new tag'
        />
      ) : (
          isOwner && (
            <span
              className='text-gray-700 cursor-pointer p-2 font-medium leading-5 text-xl mr-1 rounded  border border-gray-100 hover:bg-black hover:text-white hover:border-black'
              onClick={() => setIsEditing(true)}
            >
              + add tag
            </span>
          )
        )}
    </div>
  );
};

export default TagCloud;
