import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';

export const PrivateRoute = ({ children, ...rest }) => {
  const { currentUser, isLoadingUser } = useContext(UserContext);
  if (isLoadingUser) return <div className='w-full text-center'>Loading</div>;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentUser ? children : <Redirect to='/login' />
      }
    />
  );
};
