import React, { Fragment } from 'react';
import { useState } from 'react';

const Ingredient = ({
  ingredient,
  hovering,
  updateIngredient,
  onRemoveIngredient,
  isOwner,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputState, setInputState] = useState();

  const handleChange = (e) => {
    e.preventDefault();

    setInputState(e.target.value);
  };

  const onUpdateIngredient = () => {
    if (!inputState || inputState === '') {
      setIsEditing(false);
      return;
    }
    updateIngredient(inputState);
  };

  return (
    <Fragment>
      {isEditing ? (
        <input
          autoFocus
          type='text'
          name={ingredient}
          onChange={handleChange}
          value={inputState}
        />
      ) : (
        ingredient
      )}
      <div className={`menu-edit-ingredient ${!hovering && 'hidden'}`}>
        {isEditing ? (
          <span
            className='hover:font-medium cursor-pointer'
            onClick={onUpdateIngredient}
          >
            save
          </span>
        ) : (
          isOwner && (
            <Fragment>
              <span
                className='hover:font-medium cursor-pointer hover:bg-gray-400 p-2 rounded'
                onClick={() => {
                  setIsEditing(true);
                  setInputState(ingredient);
                }}
              >
                edit
              </span>{' '}
              <span
                className='hover:font-medium cursor-pointer hover:bg-gray-400 p-2 rounded'
                onClick={onRemoveIngredient}
              >
                remove
              </span>
            </Fragment>
          )
        )}
      </div>
    </Fragment>
  );
};

export default Ingredient;
