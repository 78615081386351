import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../providers/UserProvider';
import { Link } from 'react-router-dom';

//TODO: add 'restore susbcription' func
// user can enter secret code which is the stripe susbcriptionId
// if code === subscriptionId, grant access by adding the userObj ref to the subscriptions subcollection
const Subscriptions = () => {
  const { restoreSubscription, getSubscriptions } = useContext(UserContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [inputState, setInputState] = useState({ email: '', code: '' });

  const handleChange = (event) => {
    event.preventDefault();

    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };
  useEffect(() => {
    const fetchSubs = async () => {
      const _subs = await getSubscriptions();
      const subs = _subs.map((sub) => {
        return { id: sub.id, ...sub.data() };
      });

      setSubscriptions(subs);
    };
    fetchSubs();
  }, [restoreSubscription, getSubscriptions]);
  return (
    <div className='container mx-auto mt-12 w-1/2 '>
      <h1 className='text-xl text-gray-900 my-2 font-bold'>
        Your active subscriptions
      </h1>
      <table className='m-auto border p-4 table w-full mt-2 text-center'>
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.length > 0 ? (
            subscriptions.map((subscription) => (
              <tr key={subscription.id}>
                <td className='p-2'>{subscription.username}</td>
                <td>{subscription.email}</td>
                <td>
                  <Link
                    className='cursor-pointer px-2 py-1 bg-gray-400 hover:bg-gray-300 rounded text-sm'
                    to={`/boards/${subscription.username}`}>
                    view board
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>You have no active subscriptions</td>
              <td />
              <td />
            </tr>
          )}
        </tbody>
      </table>
      <h1 className='text-xl text-gray-900 mt-12 mb-2 font-bold'>
        Restore subscription
      </h1>
      <p className='text-gray-700 w-3/4 leading-relaxed'>
        Did you use a different email to subscribe to a recipeboard user? You
        can restore your subscription by using the e-mail you used at checkout
        together with the secret code you received in your email.
      </p>
      <input
        type='text'
        name='email'
        value={inputState['email']}
        onChange={(e) => handleChange(e)}
        className='border rounded w-3/4 m-auto mt-2 p-2'
        placeholder='e-mail address provided at checkout'></input>
      <br />
      <input
        type='text'
        name='code'
        value={inputState['code']}
        onChange={(e) => handleChange(e)}
        className='border rounded w-3/4 m-auto mt-2 p-2'
        placeholder='secret code'></input>
      <br />
      <button
        onClick={() =>
          restoreSubscription(inputState['email'], inputState['code'])
        }
        className='px-2 py-2 mt-4 text-white rounded bg-indigo-700 hover:bg-indigo-600 cursor-pointer font-medium'>
        Restore subscription
      </button>
    </div>
  );
};

export default Subscriptions;
